@import '../../utils/base';
@import '../../utils/mixins/fit-with-margin';

.map-modal__modal.map-modal__modal {
  @media (min-width: $mdMin) {
    $margin: 20px;

    @include fit-with-margin($margin);
  }

  @media (max-width: $smMax) {
    $margin: 10px;

    @include fit-with-margin($margin);
  }
}

.map-modal__modal-content.map-modal__modal-content {
  height: 100%;
}

.map-modal__modal-title.map-modal__modal-title {
  position: absolute;
  width: 100%;
  z-index: 1001;
  padding: .5rem 1rem 1rem;
  margin: 0;
  color: #fff;
  background: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
}

.map-modal__modal-body.map-modal__modal-body {
  padding: 0;
  display: flex;
  overflow: hidden;
}

.map-modal__modal.map-modal__modal .leaflet-container.leaflet-container {
  flex: 1 1 auto;
  border-radius: .3rem;
}

.map-modal__modal.map-modal__modal .leaflet-top.leaflet-top .leaflet-control.leaflet-control {
  margin-top: 60px;
}
