// Breakpoints
$xsMax: 575px;
$smMin: 576px;
$smMax: 767px;
$mdMin: 768px;
$mdMax: 991px;
$lgMin: 992px;
$lgMax: 1199px;
$xlgMin: 1200px;
$responsiveTableBreakpoint: $mdMax;

// Colors
$mainColor: #4696e5;
$lightColor: #f5f6fe;
$lightGrey: #dddddd;
$dangerColor: #dc3545;
$mediumGrey: #dee2e6;

// Misc
$headerHeight: 57px;
$asideMenuWidth: 260px;
$footer-height: 2.3rem;
$footer-margin: .8rem;

// Bootstrap overwrites
//$theme-colors: (
//  'primary': $mainColor
//);
