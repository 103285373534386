@import '../utils/base';
@import '../utils/mixins/vertical-align';

.aside-menu {
  width: $asideMenuWidth;
  background-color: white;
  box-shadow: rgba(0, 0, 0, .05) 0 8px 15px;
  position: fixed !important;
  padding-top: 13px;
  padding-bottom: 10px;
  top: $headerHeight;
  bottom: 0;
  left: 0;
  display: block;
  z-index: 1010;
  overflow-x: hidden;
  overflow-y: auto;

  @media (min-width: $mdMin) {
    padding: 30px 15px 15px;
    border-right: 1px solid #eeeeee;
  }

  @media (max-width: $smMax) {
    transition: left 300ms;
    top: $headerHeight - 3px;
    box-shadow: -10px 0 50px 11px rgba(0, 0, 0, .55);
  }
}

.aside-menu--hidden {
  @media (max-width: $smMax) {
    left: -($asideMenuWidth + 35px);
  }
}

.aside-menu__nav {
  height: 100%;
}

.aside-menu__item {
  padding: 10px 20px;
  margin: 0 -15px;
  text-decoration: none !important;
  cursor: pointer;

  @media (max-width: $smMax) {
    margin: 0;
  }
}

.aside-menu__item:hover {
  background-color: $lightColor;
}

.aside-menu__item--selected {
  color: #ffffff;
  background-color: $mainColor;
}

.aside-menu__item--selected:hover {
  color: #ffffff;
  background-color: $mainColor;
}

.aside-menu__item--divider {
  border-bottom: 1px solid #eeeeee;
  margin: 20px 0;
}

.aside-menu__item--danger {
  color: $dangerColor;
}

.aside-menu__item--push {
  margin-top: auto;
}

.aside-menu__item--danger:hover {
  color: #ffffff;
  background-color: $dangerColor;
}

.aside-menu__item-text {
  margin-left: 8px;
}
