@import '../utils/base';
@import '../utils/mixins/sticky-cell';

.visits-table {
  margin: 1.5rem 0 0;
  position: relative;
  background-color: white;
  overflow-y: hidden;
}

.visits-table__header-cell {
  cursor: pointer;
  margin-bottom: 55px;

  @include sticky-cell();

  @media (min-width: $mdMin) {
    &.visits-table__sticky {
      top: $headerHeight + 40px;
    }
  }
}

.visits-table__header-icon {
  float: right;
  margin-top: 3px;
}

.visits-table__footer-cell.visits-table__footer-cell {
  bottom: 0;
  margin-top: 34px;
  padding: .5rem;

  @include sticky-cell();
}

.visits-table__sticky.visits-table__sticky {
  position: sticky;
}
