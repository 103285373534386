.short-urls-visits-count__max-visits-control {
  cursor: help;
}

.short-url-visits-count__amount {
  transition: transform .3s ease;
  display: inline-block;
}

.short-url-visits-count__amount--big {
  transform: scale(1.5);
}
